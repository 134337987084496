<template>
  <div>
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row justify-content-md-center mt-3">
            <div class="col-md-8 col-lg-6 col-xl-5">
              <form class="text-center" @submit.prevent="login">

                <div class="widget-holder rounded" v-if="!otp">
                  <div class="widget-bg">
                    <div class="widget-body">

                      <h2 class="mb-5 text-center">Log In</h2>

                      <div class="form-group">
                        <label>Username / Email Address</label>
                        <input type="text" ref="email" v-model="email" class="form-control text-center" maxlength="254" required />
                      </div>
                      <div class="form-group">
                        <label>Password</label>
                        <input type="password" v-model="password" class="form-control text-center" maxlength="254" required />
                      </div>

                      <div class="form-group mx-3 px-5 pt-3 mb-2">
                        <button type="submit" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" :disabled="waiting"><i v-if="waiting" class="fal fa-spinner fa-spin mr-2" style="margin-left: -22px;"></i>Log In</button>
                      </div>

                      <div class="pb-3"><router-link :to="`/profile/password/reset?email=${email}`" class="text-muted fw-700 text-uppercase heading-font-family fs-11">Forgot Password?</router-link></div>

                      <div class="text-in-separator mt-4 mb-2">
                        <hr>
                        <div class="message text-muted px-2 bg-white d-inline-block">or</div>
                      </div>

                      <div class="form-group mx-3 px-5 pb-4">
                        <router-link to="/signup" role="button" class="btn btn-block btn-rounded btn-md btn-default text-uppercase fw-600 ripple">Sign Up</router-link>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="widget-holder rounded" v-if="otp">
                  <div class="widget-bg">
                    <div class="widget-body">

                      <h2 class="mb-4 pb-2 text-center">Two-Factor Authentication</h2>

                      <label>Authentication Code</label>
                      <p class="mb-4 px-sm-3">To continue, please enter the 6-digit verification code generated by your authenticator app.</p>
                      <div class="form-row">
                        <div class="col pr-sm-0 pl-sm-3">
                          <input type="text" ref="code1" v-model="tmp.code1" class="form-control text-center" @focus="otpNextField($event, 1)" @keypress="otpNumber($event, 1)" @keydown="otpDel($event, 1)" @paste="otpPaste($event, 1)" minlength="1" maxlength="1" autocomplete="off" required />
                        </div>
                        <div class="col pr-sm-0">
                          <input type="text" ref="code2" v-model="tmp.code2" class="form-control text-center" @focus="otpNextField($event, 2)" @keypress="otpNumber($event, 2)" @keydown="otpDel($event, 2)" @paste="otpPaste($event, 2)" minlength="1" maxlength="1" autocomplete="off" required />
                        </div>
                        <div class="col pr-sm-3">
                          <input type="text" ref="code3" v-model="tmp.code3" class="form-control text-center" @focus="otpNextField($event, 3)" @keypress="otpNumber($event, 3)" @keydown="otpDel($event, 3)" @paste="otpPaste($event, 3)" minlength="1" maxlength="1" autocomplete="off" required />
                        </div>
                        <div class="col pr-sm-0">
                          <input type="text" ref="code4" v-model="tmp.code4" class="form-control text-center" @focus="otpNextField($event, 4)" @keypress="otpNumber($event, 4)" @keydown="otpDel($event, 4)" @paste="otpPaste($event, 4)" minlength="1" maxlength="1" autocomplete="off" required />
                        </div>
                        <div class="col pr-sm-0">
                          <input type="text" ref="code5" v-model="tmp.code5" class="form-control text-center" @focus="otpNextField($event, 5)" @keypress="otpNumber($event, 5)" @keydown="otpDel($event, 5)" @paste="otpPaste($event, 5)" minlength="1" maxlength="1" autocomplete="off" required />
                        </div>
                        <div class="col pr-sm-3">
                          <input type="text" ref="code6" v-model="tmp.code6" class="form-control text-center" @focus="otpNextField($event, 6)" @keypress="otpNumber($event, 6)" @keydown="otpDel($event, 6)" @paste="otpPaste($event, 6)" minlength="1" maxlength="1" autocomplete="off" required />
                        </div>
                      </div>

                      <div class="form-group mx-3 px-5 mt-4 pt-2 mb-2">
                        <button type="submit" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" :disabled="waiting"><i v-if="waiting" class="fal fa-spinner fa-spin mr-2" style="margin-left: -22px;"></i>Continue</button>
                      </div>

                      <div class="pb-3"><button type="button" class="btn btn-link text-muted fw-700 text-uppercase heading-font-family fs-11" @click="reset">Log In Another Way</button></div>

                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import toast from '@/modules/toast';

export default {
  data() {
    return {
      email: '',
      password: '',
      code: '',
      tmp: {
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: '',
      },
      otp: false,
      waiting: false,
    };
  },
  methods: {
    otpNumber(e, fieldnum) {
      if (e.key >= '0' && e.key <= '9') {
        this.tmp[`code${fieldnum}`] = String.fromCharCode(e.keyCode);
        if (fieldnum < 6) {
          this.$nextTick(() => { this.$refs[`code${fieldnum + 1}`].focus(); });
        } else {
          this.login();
        }
      }
      e.preventDefault();
      return false;
    },
    otpDel(e, fieldnum) {
      if ((e.key >= '0' && e.key <= '9') || e.keyCode === 9 || e.keyCode === 17 || e.keyCode === 86) {
        return true;
      } if (e.keyCode === 8) {
        if (fieldnum > 1 && this.tmp[`code${fieldnum}`] === '') {
          // switch to previous field
          this.$nextTick(() => { this.$refs[`code${fieldnum - 1}`].focus(); });
        }
        return true;
      }
      e.preventDefault();
      return false;
    },
    otpPaste(e, currentfieldnum) {
      let fieldnum = currentfieldnum;
      const v = e.clipboardData.getData('text/plain');
      for (let i = 0; i < v.length && fieldnum <= 6; i += 1, fieldnum += 1) {
        if (v.charCodeAt(i) >= 48 && v.charCodeAt(i) <= 57) {
          this.tmp[`code${fieldnum}`] = v[i];
        } else {
          break;
        }
      }
      if (fieldnum <= 6) {
        this.$nextTick(() => { this.$refs[`code${fieldnum}`].focus(); });
      } else {
        this.login();
      }
      e.preventDefault();
      return false;
    },
    otpNextField(e, fieldnum) {
      if (this.tmp[`code${fieldnum}`] === '') {
        let nextFieldNum = 1;
        for (;; nextFieldNum += 1) {
          if (this.tmp[`code${nextFieldNum}`] === '') {
            break;
          }
        }
        this.$nextTick(() => { this.$refs[`code${nextFieldNum}`].focus(); });
        e.preventDefault();
        return false;
      }
      return true;
    },
    login() {
      this.waiting = true;
      this.code = this.tmp.code1 + this.tmp.code2 + this.tmp.code3 + this.tmp.code4 + this.tmp.code5 + this.tmp.code6;
      this.$auth.logIn(this.email, this.password, this.code !== '' ? this.code : undefined)
        .then(() => {
          this.waiting = false;
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else if (this.$auth.profile.update_billing) {
            this.$router.push('/profile/billing/update');
          } else if (this.$auth.profile.verify_email) {
            this.$router.push('/profile/verify');
          } else {
            this.$router.push('/dashboard');
          }
        })
        .catch((e) => {
          this.waiting = false;
          if (e.response.status === 401) {
            this.otp = false;
            this.code = '';
            this.tmp.code1 = '';
            this.tmp.code2 = '';
            this.tmp.code3 = '';
            this.tmp.code4 = '';
            this.tmp.code5 = '';
            this.tmp.code6 = '';
            toast.danger(this, 'Incorrect username or password');
          } else if (e.response.status === 503) {
            // login ok; OTP required
            if (this.otp) {
              toast.danger(this, 'Incorrect OTP code');
              this.code = '';
              this.tmp.code1 = '';
              this.tmp.code2 = '';
              this.tmp.code3 = '';
              this.tmp.code4 = '';
              this.tmp.code5 = '';
              this.tmp.code6 = '';
            }
            this.otp = true;
            this.$nextTick(() => { this.$refs.code1.focus(); });
          } else {
            toast.danger(this, e.response.data.message);
          }
        });
    },
    reset() {
      this.email = '';
      this.password = '';
      this.code = '';
      this.tmp.code1 = '';
      this.tmp.code2 = '';
      this.tmp.code3 = '';
      this.tmp.code4 = '';
      this.tmp.code5 = '';
      this.tmp.code6 = '';
      this.otp = false;
    },
  },
  mounted() {
    this.$nextTick(() => { this.$refs.email.focus(); });
  },
  components: {
    Header,
  },
};
</script>
